import React, { ChangeEvent, useCallback, useContext } from 'react'
import {
  PRadioButtonWrapper,
  PText,
} from '@porsche-design-system/components-react'
import styles from './FeedbackFlyout.module.css'
import { t } from 'i18next'
import type { FeedbackFormValues, useFeedback } from './useFeedback'
import { UserInfo } from '../../../App'

interface Props {
  setValues: ReturnType<typeof useFeedback>['setValues']
  values: FeedbackFormValues
}

type RoleKey =
  | 'singlePurchaser'
  | 'groupPurchaser'
  | 'purchaseManager'
  | 'otherRole'

const roles: RoleKey[] = [
  'singlePurchaser',
  'groupPurchaser',
  'purchaseManager',
  'otherRole',
]

export const FeedbackFormRole: React.FC<Props> = ({ setValues, values }) => {
  const { isDealer } = useContext(UserInfo)

  const onRoleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      setValues((prevState) => ({
        ...prevState,
        role: event.target.value as FeedbackFormValues['role'],
      })),
    [setValues],
  )

  if (!isDealer) {
    return null
  }

  return (
    <div className={styles.roleWrapper}>
      <PText weight="bold">{t('dealerView.feedback.role.heading')}</PText>
      <div className={styles.roleContainer}>
        {roles.map((roleKey) => {
          const roleLabel = t(`dealerView.feedback.role.${roleKey}`)

          return (
            <PRadioButtonWrapper key={roleKey} label={roleLabel}>
              <input
                type="radio"
                name="role"
                value={roleKey}
                checked={values.role === roleKey}
                onChange={onRoleChange}
              />
            </PRadioButtonWrapper>
          )
        })}
      </div>
    </div>
  )
}
