import React, { JSX, useCallback, useState } from 'react'
import { PFlyout, PHeading } from '@porsche-design-system/components-react'
import { t } from 'i18next'
import { FeedbackButton } from './FeedbackButton'
import { FeedbackForm } from './FeedbackForm'

export const FeedbackFlyout = (): JSX.Element => {
  const [isFlyoutOpen, setIsFlyoutOpen] = useState<boolean>(false)

  const onOpen = useCallback(() => {
    setIsFlyoutOpen(true)
  }, [])

  const onDismiss = useCallback(() => {
    setIsFlyoutOpen(false)
  }, [])

  return (
    <>
      <FeedbackButton onClick={onOpen} />
      <PFlyout
        open={isFlyoutOpen}
        onDismiss={onDismiss}
        aria={{ 'aria-label': 'Feedback flyout' }}
        disableBackdropClick={true}
      >
        <PHeading slot="header" size="large" tag="h2">
          {t('sharedView.feedback.feedback')}
        </PHeading>

        <FeedbackForm isFlyoutOpen={isFlyoutOpen} onClose={onDismiss} />
      </PFlyout>
    </>
  )
}
