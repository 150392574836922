import {
  PSegmentedControl,
  PSegmentedControlItem,
  PText,
  SegmentedControlUpdateEventDetail,
} from '@porsche-design-system/components-react'
import styles from './FeedbackFlyout.module.css'
import { t } from 'i18next'
import React, { useCallback } from 'react'
import type { FeedbackFormValues, useFeedback } from './useFeedback'

const ratingValues = [1, 2, 3, 4, 5]

interface Props {
  setValues: ReturnType<typeof useFeedback>['setValues']
  values: FeedbackFormValues
}

export const FeedbackFormRating: React.FC<Props> = ({ setValues, values }) => {
  const onRatingChange = useCallback(
    (e: CustomEvent<SegmentedControlUpdateEventDetail>) =>
      setValues((prevState) => ({
        ...prevState,
        rating: e.detail.value as FeedbackFormValues['rating'],
      })),
    [setValues],
  )

  return (
    <div>
      <PText className={styles.marginBottom} weight="bold">
        {t('sharedView.feedback.howSatisfiedAreYou')}
      </PText>
      <div className={`${styles.ratingWrapper} ${styles.marginBottom}`}>
        <PText>{t('sharedView.feedback.veryDissatisfied')}</PText>
        <PSegmentedControl
          aria-label="Choose a satisfaction rating"
          className={`${styles.ratingControl}`}
          value={values.rating}
          onUpdate={onRatingChange}
        >
          {ratingValues.map((value) => (
            <PSegmentedControlItem key={value} value={value}>
              {value}
            </PSegmentedControlItem>
          ))}
        </PSegmentedControl>
        <PText>{t('sharedView.feedback.verySatisfied')}</PText>
      </div>
    </div>
  )
}
