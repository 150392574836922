import loadEnvironment from '../loadEnvironment'
import { toZonedTime } from 'date-fns-tz'
import { Auction } from '../Models/Auction'

export const priceFormatting = (price: number): string => {
  return new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  }).format(price)
}

export const kilometerFormatting = (mileage: number): string => {
  return new Intl.NumberFormat('de-DE', {
    style: 'unit',
    unit: 'kilometer',
  }).format(mileage)
}

export const isAuctionLive = (): boolean => {
  const currentHour = convertUtcTimeToGermanTime(new Date()).getHours()
  const { AUCTION_START_TIME, AUCTION_END_TIME } = loadEnvironment()

  //TODO this logic will fail if we have auction times are something like 12.30
  return (
    Number(AUCTION_START_TIME) <= currentHour &&
    currentHour < Number(AUCTION_END_TIME)
  )
}

export const hasAuctionEnded = (): boolean => {
  const currentHour = convertUtcTimeToGermanTime(new Date()).getHours()
  const { AUCTION_END_TIME } = loadEnvironment()

  //TODO this logic will fail if we have auction end time at something like 12.30
  return currentHour < Number(AUCTION_END_TIME)
}

export const convertUtcTimeToGermanTime = (utcTime: Date): Date => {
  return toZonedTime(utcTime, 'Europe/Berlin')
}

export const isTodaysAuction = (auction?: Auction): boolean => {
  if (auction == null) return false

  const currentDate = convertUtcTimeToGermanTime(new Date())
    .toISOString()
    .split('T')[0]
  const auctionStartDate = convertUtcTimeToGermanTime(
    new Date(auction.start_date),
  )
    .toISOString()
    .split('T')[0]
  const auctionEndDate = convertUtcTimeToGermanTime(new Date(auction.end_date))
    .toISOString()
    .split('T')[0]
  return auctionStartDate === currentDate && auctionEndDate === currentDate
}

/**
 * This function replaces the componentsReady() function from Porsche Design System, due in some tests the test
 * get stuck in componentsReady(). The code is provided by PDS
 * {@link https://designsystem.porsche.com/v3/developing/components-ready/#basic-example-when-defined}
 * and only slightly modified
 * @param el
 */
export const whenDefined = async (
  el: HTMLElement = document.body,
): Promise<number> => {
  const allElements = el.querySelectorAll('*')

  const undefinedElements = Array.from(allElements).filter(
    (element) => customElements.get(element.localName) === undefined,
  )

  const promises = undefinedElements.map((element) =>
    customElements.whenDefined(element.localName),
  )

  try {
    await Promise.all(promises)

    return promises.length
  } catch {
    return 0
  }
}
