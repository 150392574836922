import {
  PButton,
  PHeading,
  PInlineNotification,
  PPopover,
  PTextFieldWrapper,
} from '@porsche-design-system/components-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './AuctionReport.module.css'

export default function AuctionReport() {
  const { t } = useTranslation()

  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <PHeading size="medium">{t('adminView.auctionReportTitle')}</PHeading>
        <PPopover>{t('adminView.bidReportHint')}</PPopover>
      </div>
      <PInlineNotification
        state="error"
        heading={'ERROR heading'}
        description={'error description'}
      />
      <div className={styles.formWrapper}>
        <PTextFieldWrapper
          className={styles.inputWrapper}
          label={t('adminView.startDate')}
          state={'error'}
          message={'startDateError'}
        >
          <input type="date" />
        </PTextFieldWrapper>
        <PTextFieldWrapper
          className={styles.inputWrapper}
          label={t('adminView.endDate')}
        >
          <input type="date" />
        </PTextFieldWrapper>
        <PButton className={styles.exportButton} variant="primary">
          {t('sellerView.exportButton')}
        </PButton>
      </div>
    </div>
  )
}
