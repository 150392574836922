import { Vehicle } from '../../../Models/Vehicle'
import {
  PButton,
  PButtonGroup,
  PCheckboxWrapper,
  PHeading,
  PIcon,
  PInlineNotification,
  PModal,
  PPopover,
  PSelectWrapper,
  PTag,
  PText,
  PTextFieldWrapper,
  useToastManager,
} from '@porsche-design-system/components-react'
import { getImg } from './ImageGallery'
import styles from './DetailsPage.module.css'
import { PorscheCenter } from '../../../Models/PorscheCenter'
import { useNavigate } from 'react-router-dom'
import { callBackendAPI } from '../../../api'
import loadEnvironment from '../../../loadEnvironment'
import React, { useEffect, useState } from 'react'
import {
  kilometerFormatting,
  priceFormatting,
} from '../../../helpers/helperFunctions'
import { useTranslation } from 'react-i18next'
import { NumberFormatValues, NumericFormat } from 'react-number-format'
import { AxiosError } from 'axios'
import { Bid } from '../../../Models/Bid'
import { ErrorSerializer } from '../../../Models/ErrorSerializer'
import PackageVehicleImages from './PackageDetailsPage/PackageVehicleImages'

const environment = loadEnvironment()

const transformFloatToNumberFormatValues = (floatNumber: number) => {
  return {
    formattedValue: new Intl.NumberFormat('de-DE').format(floatNumber),
    floatValue: floatNumber,
  } as NumberFormatValues
}

type BiddingModalProps = {
  vehicleWithDetails: Vehicle | null
  porscheCenters: PorscheCenter[] | null
  open: boolean
  closingHandler: () => void
  currentHighestBid: number
  maxBidId: string | undefined
  userMaxBid: Bid | undefined
  isPackageAuction?: boolean | undefined
  isQuickBid: boolean
  resetFn?: () => void
}

const BiddingModal = (props: BiddingModalProps) => {
  const minimumValidBidAmount = (
    props: BiddingModalProps,
    raiseByAtLeast100: boolean = false,
  ) => {
    return props.currentHighestBid !== 0
      ? props.userMaxBid && props.maxBidId === props.userMaxBid._id
        ? Math.max(
            props.currentHighestBid + 100,
            Number(props.userMaxBid.bid_amount) + (raiseByAtLeast100 ? 100 : 1),
          )
        : props.currentHighestBid + 100
      : Number(props.vehicleWithDetails?.auction?.initial_price)
  }

  const { t } = useTranslation()
  const { vehicleWithDetails, porscheCenters, open, closingHandler } = props
  const { addMessage } = useToastManager()
  const [selectedPorscheCenter, setSelectedPorscheCenter] = useState(
    props.userMaxBid?.porsche_center_id ?? '',
  )
  const [bidAmount, setBidAmount] = useState(
    props.isQuickBid
      ? transformFloatToNumberFormatValues(minimumValidBidAmount(props, true))
      : ({} as NumberFormatValues),
  )
  const [bidAmountRepeated, setBidAmountRepeated] = useState(
    props.isQuickBid
      ? transformFloatToNumberFormatValues(minimumValidBidAmount(props, true))
      : ({} as NumberFormatValues),
  )
  const [termsAndConditions, setTermsAndConditions] = useState<boolean>(
    props.isQuickBid,
  )
  const [extraConfirmationForPackages, setExtraConfirmationForPackages] =
    useState<boolean>(props.isQuickBid)
  const [errorHeading, setErrorHeading] = useState<string | null>(null)
  const [errorDescription, setErrorDescription] = useState<string | null>(null)
  const [warning, setWarning] = useState<string | null>(null)
  const navigate = useNavigate()

  const getDateOfFirstRegistration = () => {
    if (vehicleWithDetails?.date_of_first_registration) {
      const date = new Date(vehicleWithDetails.date_of_first_registration)
      return `${t('sharedView.firstRegistration')}: ${(
        '0' +
        (date.getMonth() + 1)
      ).slice(-2)}/${date.getFullYear().toString().slice(-2)}`
    }
  }

  const [porscheCenterValid, setPorscheCenterValid] = useState<boolean>(false)
  const [bidAmountValid, setBidAmountValid] = useState<boolean>(false)
  const [bidAmountRepeatedValid, setBidAmountRepeatedValid] =
    useState<boolean>(false)
  const [emptyFieldErrorsVisible, setEmptyFieldErrorsVisible] =
    useState<boolean>(false)

  const pleaseSelectTranslation = t('dealerView.pleaseSelect')

  useEffect(() => {
    setPorscheCenterValid(
      selectedPorscheCenter !== '' &&
        selectedPorscheCenter !== pleaseSelectTranslation,
    )

    setBidAmountValid(
      (bidAmount?.floatValue ?? 0) >= minimumValidBidAmount(props, false),
    )

    setBidAmountRepeatedValid(
      bidAmountRepeated.floatValue !== undefined &&
        bidAmount.floatValue === bidAmountRepeated.floatValue,
    )
  }, [
    selectedPorscheCenter,
    bidAmount,
    bidAmountRepeated,
    pleaseSelectTranslation,
    props,
  ])

  return (
    <>
      <PModal
        className={styles.modal}
        open={open}
        onDismiss={() => closingHandler()}
      >
        <PHeading
          slot="header"
          size="large"
          tag="h2"
          data-testid="biddingModalHeader"
        >
          {t('dealerView.bidButton')}
        </PHeading>
        {(warning || errorHeading) && (
          <div className={styles.modalError}>
            {warning && (
              <PInlineNotification
                state="warning"
                description={warning.toString()}
                onDismiss={() => setWarning(null)}
              />
            )}
            {errorHeading && (
              <PInlineNotification
                state="error"
                heading={errorHeading.toString()}
                description={errorDescription?.toString()}
                onDismiss={() => {
                  setErrorHeading(null)
                  setErrorDescription(null)
                }}
              />
            )}
          </div>
        )}

        {props.isPackageAuction ? (
          <PackageVehicleImages
            vehicles={vehicleWithDetails?.auction?.vehicles ?? []}
          />
        ) : (
          <></>
        )}
        <div className={styles.modalHeader}>
          {!props.isPackageAuction && (
            <div className={styles.modalImage}>
              {getImg(
                vehicleWithDetails?.gallery?.extcam1,
                'exterior picture of the car',
              )}
            </div>
          )}

          <div className={styles.modalHeaderAndSubHeader}>
            <PHeading tag={'h3'} size="medium">
              {props.isPackageAuction
                ? `${t('sharedView.packageSummaryPackageID')}: ${
                    vehicleWithDetails?.auction?.package_number
                  }`
                : vehicleWithDetails?.derivative}
              {vehicleWithDetails?.extra_tyres && !props.isPackageAuction ? (
                <PIcon
                  name="snowflake"
                  size="small"
                  style={{ marginLeft: '3px', marginTop: '3px' }}
                ></PIcon>
              ) : (
                <></>
              )}
            </PHeading>
            {props.isPackageAuction ? (
              <>
                {t('sharedView.packageSummaryVehicleCount')}:{' '}
                {vehicleWithDetails?.auction?.vins?.length} |{' '}
                {props.currentHighestBid !== 0 // TODO: extract into variable
                  ? `${t('dealerView.currentHighestBid')}: ${priceFormatting(
                      Number(props.currentHighestBid),
                    )} (${t('sharedView.net')})`
                  : `${t('sharedView.vehicleInitialPrice')}: ${priceFormatting(
                      Number(vehicleWithDetails?.auction?.initial_price),
                    )} (${t('sharedView.net')})`}
              </>
            ) : (
              <>
                <div>
                  <PTag color="background-base" className={styles.sub}>
                    {vehicleWithDetails?.mileage
                      ? `${t(
                          'sharedView.vehicleMileage',
                        )}: ${kilometerFormatting(
                          vehicleWithDetails?.mileage,
                        )} |`
                      : '- |'}
                  </PTag>
                  <PTag color="background-base" className={styles.sub}>
                    {getDateOfFirstRegistration() + ' |'}
                  </PTag>
                  <br />
                  <PTag color="background-base">
                    {vehicleWithDetails?.gross_price
                      ? t('sharedView.vehicleListingPrice') +
                        ': ' +
                        priceFormatting(vehicleWithDetails.gross_price) +
                        ` (${t('sharedView.gross')})`
                      : '-'}
                  </PTag>
                </div>
                <PText color="contrast-high">
                  <PTag color="background-surface">
                    {props.currentHighestBid !== 0
                      ? `${t('dealerView.currentHighestBid')}: ${priceFormatting(Number(props.currentHighestBid))} (${t('sharedView.net')})`
                      : `${t('sharedView.vehicleInitialPrice')}: ${priceFormatting(Number(vehicleWithDetails?.auction?.initial_price))} (${t('sharedView.net')})`}
                  </PTag>
                </PText>
              </>
            )}
          </div>
        </div>

        {props.isPackageAuction && <br />}

        <PSelectWrapper
          className={styles.modalInputRow}
          label={t('sharedView.porscheCenter').toString()}
          state={
            emptyFieldErrorsVisible && !porscheCenterValid ? 'error' : 'none'
          }
          message={t('dealerView.pleaseSelectAPorscheCenter')}
        >
          <select
            aria-label={'Porsche centers'}
            value={selectedPorscheCenter}
            onChange={(e) => setSelectedPorscheCenter(e.target.value)}
          >
            <option>{t('dealerView.pleaseSelect')}</option>
            {porscheCenters?.map((center) => (
              <option key={center.id} value={center.id}>
                {center.displayName}
              </option>
            ))}
          </select>
        </PSelectWrapper>

        <div>
          <PText className={styles.modalInputLabel}>
            {t('dealerView.myMaximumBid')} ({t('sharedView.net')})
            <PPopover
              className={styles.popover}
              aria={{ 'aria-label': 'Additional information' }}
            >
              {t('dealerView.bidMustBe100EurosHigher')}
            </PPopover>
          </PText>
          <PTextFieldWrapper
            className={styles.modalInputRow}
            unit="€"
            unitPosition="suffix"
            state={
              (bidAmount.floatValue === undefined &&
                !emptyFieldErrorsVisible) ||
              bidAmountValid
                ? 'none'
                : 'error'
            }
            message={t('dealerView.bidIsLowerThanMinimumValidAmount', {
              amount: minimumValidBidAmount(props),
              formatParams: {
                amount: { locale: 'de-DE' },
              },
            }).toString()}
            data-testid="bid-amount-wrapper"
          >
            <NumericFormat
              aria-label="bid-amount"
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={0}
              fixedDecimalScale
              value={bidAmount.floatValue}
              onValueChange={(e) => {
                setBidAmount(e)
              }}
            />
          </PTextFieldWrapper>
        </div>

        <div>
          <PText className={styles.modalInputLabel}>
            {t('dealerView.confirmMyMaximumBid')} ({t('sharedView.net')})
            <PPopover
              className={styles.popover}
              aria={{ 'aria-label': 'Additional information' }}
            >
              {t('dealerView.bidMustBe100EurosHigher')}
            </PPopover>
          </PText>
          <PTextFieldWrapper
            className={styles.modalInputRow}
            unit="€"
            unitPosition="suffix"
            state={
              (bidAmountRepeated.floatValue === undefined &&
                !emptyFieldErrorsVisible) ||
              bidAmountRepeatedValid
                ? 'none'
                : 'error'
            }
            message={t('dealerView.valuesDontMatch').toString()}
          >
            <NumericFormat
              aria-label="bid-amount-repeated"
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={0}
              fixedDecimalScale
              value={bidAmountRepeated.floatValue}
              onValueChange={(e) => {
                setBidAmountRepeated(e)
              }}
            />
          </PTextFieldWrapper>
        </div>

        {!props.isQuickBid && (
          <PCheckboxWrapper
            className={styles.modalInputRow}
            state={
              emptyFieldErrorsVisible && !termsAndConditions ? 'error' : 'none'
            }
          >
            <span slot="label" id="t-and-c-id">
              {t('dealerView.termsAndConditionsPartOne')}{' '}
              <a
                href={`../${t('dealerView.termsAndConditionsFilename')}`}
                target={'_blank'}
                rel="noreferrer"
              >
                {t('dealerView.termsAndConditionsPartTwo')}
              </a>
            </span>
            <input
              type="checkbox"
              aria-label="terms-and-conditions"
              aria-labelledby="t-and-c-id"
              checked={termsAndConditions}
              onChange={(e) => setTermsAndConditions(e.target.checked)}
            />
            <span slot="message" id="message-id">
              {t('dealerView.pleaseAcceptTermsAndConditions')}
            </span>
          </PCheckboxWrapper>
        )}

        {!props.isQuickBid && props.isPackageAuction && (
          <PCheckboxWrapper
            className={styles.modalInputRow}
            state={
              emptyFieldErrorsVisible && !extraConfirmationForPackages
                ? 'error'
                : 'none'
            }
          >
            <span slot="label" id="extra">
              {t('dealerView.extraConfirmationForBidsOnPackages')}
            </span>
            <input
              type="checkbox"
              aria-label="extra-confirmation"
              aria-labelledby="extra-confirmation-id"
              checked={extraConfirmationForPackages}
              onChange={(e) =>
                setExtraConfirmationForPackages(e.target.checked)
              }
            />
            <span slot="message" id="extra-message-id">
              {t('dealerView.pleaseConfirmPackageDetails')}
            </span>
          </PCheckboxWrapper>
        )}

        <PButtonGroup className={'footer'}>
          <PButton
            type="button"
            aria-label="submit-button"
            disabled={
              !porscheCenterValid ||
              !bidAmountValid ||
              !bidAmountRepeatedValid ||
              bidAmountRepeated.floatValue !== bidAmount.floatValue ||
              !termsAndConditions ||
              (props.isPackageAuction && !extraConfirmationForPackages)
            }
            onMouseDown={() => {
              setEmptyFieldErrorsVisible(true)
            }}
            onClick={() => {
              if (!bidAmountValid) return

              callBackendAPI(
                `${environment.B2B_BACKEND_BASE_URL}/api/bids`,
                'POST',
                {
                  auction_id: vehicleWithDetails?.auction?._id,
                  porsche_center_name: porscheCenters
                    ?.filter((center) => center.id === selectedPorscheCenter)
                    .map((center) => center.displayName)
                    .toString(),
                  porsche_center_id: selectedPorscheCenter,
                  porsche_center_country: porscheCenters
                    ?.filter((center) => center.id === selectedPorscheCenter)
                    .map((center) => center.country)
                    .toString(),
                  bid_amount: bidAmount.floatValue?.toString(),
                },
              )
                .then((response) => {
                  if (response.status === 201 && response.data.length === 1) {
                    const maxBidValue =
                      bidAmount.floatValue != null
                        ? priceFormatting(bidAmount.floatValue)
                        : undefined
                    let auctionInfo = props.isPackageAuction
                      ? t('sharedView.packageNumber')
                      : t('sharedView.vehicleIdentificator')
                    auctionInfo += ': '
                    auctionInfo += props.isPackageAuction
                      ? props.vehicleWithDetails?.auction?.package_number
                      : props.vehicleWithDetails?.auction?.vin

                    switch (response.data[0].success) {
                      case 0:
                        setWarning(t('dealerView.youHaveBeenOutbid'))
                        setErrorHeading(null)
                        setErrorDescription(null)
                        break
                      case 1:
                        navigate('/')
                        addMessage({
                          text: t('dealerView.youAreNowTheHighestBidder', {
                            bidAmount: maxBidValue,
                            auctionInfo: auctionInfo,
                          }),
                          state: 'success',
                        })
                        closingHandler()
                        break
                      case 2:
                        navigate('/')
                        addMessage({
                          text: t('dealerView.youRaisedYourMaxBid', {
                            bidAmount: maxBidValue,
                            auctionInfo: auctionInfo,
                          }),
                          state: 'success',
                        })
                        closingHandler()
                        break
                      default:
                        throw new Error('unknown bidding status')
                    }
                    if (props.resetFn) props.resetFn()
                  } else {
                    throw new Error('unknown response status and/or body')
                  }
                })
                .catch((error) => {
                  const axiosErrorResponse = (
                    error as AxiosError<ErrorSerializer>
                  ).response
                  const translationKey = axiosErrorResponse?.data.translationKey

                  setErrorHeading(
                    t(`exceptions.${translationKey || 'unexpectedError'}`),
                  )

                  if (!translationKey) {
                    setErrorDescription(
                      `Trace ID: ${axiosErrorResponse?.headers['x-trace-id']}`,
                    )
                  }

                  setWarning(null)
                })
            }}
          >
            {t('dealerView.legallyBoundBidButton')}
          </PButton>
        </PButtonGroup>
      </PModal>
    </>
  )
}

export default BiddingModal
