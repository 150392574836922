import { useCallback, useEffect, useState } from 'react'
import type { Dispatch, SetStateAction } from 'react'
import { callBackendAPI } from '../../../api'
import loadEnvironment from '../../../loadEnvironment'
import { AxiosError } from 'axios'
import { ErrorSerializer } from '../../../Models/ErrorSerializer'
import i18n from '../../../setupTranslations'

const environment = loadEnvironment()

export interface FeedbackFormState {
  isSubmitDisabled?: boolean
  isSubmitted?: boolean
  isSubmitting?: boolean
  errorHeading?: string
  errorDescription?: string
}

export interface FeedbackFormValues {
  rating?: 1 | 2 | 3 | 4 | 5
  comment?: string
  role?: string
  isAnonymous: boolean
}

export const useFeedback = (): {
  onResetForm: () => void
  onSubmit: () => void
  onResetError: () => void
  setValues: Dispatch<SetStateAction<FeedbackFormValues>>
  state: FeedbackFormState
  values: FeedbackFormValues
} => {
  const [state, setState] = useState<FeedbackFormState>({
    isSubmitDisabled: true,
  })
  const [values, setValues] = useState<FeedbackFormValues>({
    isAnonymous: false,
  })

  useEffect(() => {
    if (values.rating) {
      setState((prev) => ({ ...prev, isSubmitDisabled: false }))
    }
  }, [values.rating])

  const onSubmit = useCallback(() => {
    setState({ isSubmitting: true })
    callBackendAPI(`${environment.B2B_BACKEND_BASE_URL}/api/feedback`, 'POST', {
      rating: values.rating!,
      comment: values.comment,
      role: values.role,
      isAnonymous: values.isAnonymous,
      currentURL: window.location.href,
      userAgent: window.navigator.userAgent,
      userLanguage: i18n.language,
    })
      .then(() => {
        setState({ isSubmitted: true, isSubmitting: false })
      })
      .catch((error: AxiosError) => {
        const axiosErrorResponse = (error as AxiosError<ErrorSerializer>)
          .response
        setState({
          isSubmitted: false,
          isSubmitting: false,
          errorHeading: `exceptions.${axiosErrorResponse?.data.translationKey || 'unexpectedError'}`,
          errorDescription: `Trace ID: ${axiosErrorResponse?.headers['x-trace-id']}`,
        })
      })
  }, [values.rating, values.comment, values.role, values.isAnonymous])

  const onResetForm = useCallback(() => {
    setState({ isSubmitDisabled: true })
    setValues({ rating: undefined, comment: '', isAnonymous: false })
  }, [])

  const onResetError = useCallback(() => {
    setState((prev) => ({
      ...prev,
      errorHeading: undefined,
      errorDescription: undefined,
    }))
  }, [])

  return {
    onResetForm,
    onSubmit,
    onResetError,
    setValues,
    state,
    values,
  }
}
