import React, { useCallback, useEffect } from 'react'
import styles from './FeedbackFlyout.module.css'
import { useFeedback } from './useFeedback'
import { FeedbackFormRating } from './FeedbackFormRating'
import { FeedbackFormTextbox } from './FeedbackFormTextbox'
import { FeedbackFormSubmit } from './FeedbackFormSubmit'
import { FeedbackFormThanks } from './FeedbackFormThanks'
import { FeedbackFormRole } from './FeedbackFormRole'
import { PCheckboxWrapper } from '@porsche-design-system/components-react'
import { t } from 'i18next'

interface Props {
  isFlyoutOpen: boolean
  onClose: () => void
}

export const FeedbackForm: React.FC<Props> = ({ isFlyoutOpen, onClose }) => {
  const { onResetForm, onSubmit, onResetError, setValues, state, values } =
    useFeedback()

  useEffect(() => {
    if (isFlyoutOpen) {
      onResetForm()
    }
  }, [isFlyoutOpen, onResetForm])

  const onCheckboxClicked = useCallback(
    () =>
      setValues((prevState) => ({
        ...prevState,
        isAnonymous: !prevState.isAnonymous,
      })),
    [setValues],
  )

  return !state.isSubmitted ? (
    <div className={styles.formWrapper}>
      <FeedbackFormRating setValues={setValues} values={values} />
      <FeedbackFormTextbox setValues={setValues} values={values} />
      <FeedbackFormRole setValues={setValues} values={values} />
      <PCheckboxWrapper
        label={t('sharedView.feedback.sendFeedbackAnonymously')}
        hideLabel={false}
      >
        <input
          type="checkbox"
          name="send-feedback-anonymously-checkbox"
          aria-label="send-feedback-anonymously-checkbox-aria"
          checked={values.isAnonymous}
          onChange={onCheckboxClicked}
        />
      </PCheckboxWrapper>

      <FeedbackFormSubmit
        state={state}
        onSubmit={onSubmit}
        onCancel={onClose}
        onDismissError={onResetError}
      />
    </div>
  ) : (
    <FeedbackFormThanks onClose={onClose} />
  )
}
