import type { FeedbackFormState } from './useFeedback'
import React from 'react'
import {
  PButton,
  PButtonGroup,
  PInlineNotification,
} from '@porsche-design-system/components-react'
import { t } from 'i18next'

interface Props {
  state: FeedbackFormState
  onSubmit: () => void
  onCancel: () => void
  onDismissError: () => void
}

export const FeedbackFormSubmit: React.FC<Props> = ({
  state,
  onSubmit,
  onCancel,
  onDismissError,
}) => {
  const { isSubmitting, isSubmitDisabled, errorHeading, errorDescription } =
    state

  return (
    <>
      {errorHeading ? (
        <PInlineNotification
          state="error"
          heading={t(errorHeading)}
          description={errorDescription}
          onDismiss={onDismissError}
        />
      ) : (
        <></>
      )}
      <PButtonGroup slot="footer">
        <PButton
          type="button"
          disabled={isSubmitDisabled || isSubmitting}
          loading={isSubmitting}
          onClick={onSubmit}
        >
          {t('sharedView.feedback.submitFeedback')}
        </PButton>
        <PButton type="button" variant="secondary" onClick={onCancel}>
          {t('sharedView.feedback.cancelButton')}
        </PButton>
      </PButtonGroup>
    </>
  )
}
