import React, { ChangeEvent, useCallback } from 'react'
import {
  PText,
  PTextareaWrapper,
} from '@porsche-design-system/components-react'
import styles from './FeedbackFlyout.module.css'
import { t } from 'i18next'
import type { FeedbackFormValues, useFeedback } from './useFeedback'

interface Props {
  setValues: ReturnType<typeof useFeedback>['setValues']
  values: FeedbackFormValues
}

export const FeedbackFormTextbox: React.FC<Props> = ({ setValues, values }) => {
  const onTextChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) =>
      setValues((prevState) => ({
        ...prevState,
        comment: event.target.value as FeedbackFormValues['comment'],
      })),
    [setValues],
  )

  return (
    <div>
      <PText weight="bold" className={styles.marginBottom}>
        {t('sharedView.feedback.whatCanWeDoToImprove')}
      </PText>
      <PTextareaWrapper
        className={styles.marginBottom}
        label={'what-can-we-do-to-improve-textarea'}
        hideLabel={true}
        showCounter={true}
      >
        <textarea
          maxLength={500}
          name="what-can-we-do-to-improve-textarea"
          value={values.comment}
          onChange={onTextChange}
        />
      </PTextareaWrapper>
    </div>
  )
}
