import React, { RefObject, useRef, useState } from 'react'
import useAccessToken from '../Hooks/useAccessToken'
import SockJsClient from 'react-stomp'
import loadEnvironment from '../loadEnvironment'
import { WebSocketNotification } from './Content/LiveSaleTab/LiveSaleTab'

declare type SockJsClientType = typeof import('react-stomp')
const environment = loadEnvironment()
const socketConnectionURL = `${environment.B2B_BACKEND_BASE_URL}/ws-notifications`
const Websocket = (props: {
  notificationHandler: (notification: WebSocketNotification) => void
}) => {
  const [sockJsKey, setSockJsKey] = useState(0)
  const accessToken = useAccessToken(sockJsKey)

  const sockJsRef: RefObject<SockJsClientType> = useRef(null)

  return (
    <>
      {accessToken && (
        <SockJsClient
          url={`${socketConnectionURL}?token=Bearer%20${accessToken}`}
          topics={['/topic/auction-status']}
          onMessage={props.notificationHandler}
          onConnectFailure={() => {
            sockJsRef.current.disconnect()
            setSockJsKey(sockJsKey + 1)
          }}
          ref={sockJsRef}
          key={sockJsKey}
        />
      )}
    </>
  )
}

export default Websocket
