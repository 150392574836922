import React from 'react'
import styles from './FeedbackFlyout.module.css'
import { t } from 'i18next'
import {
  PHeading,
  PText,
  PButton,
} from '@porsche-design-system/components-react'

interface Props {
  onClose: () => void
}

export const FeedbackFormThanks: React.FC<Props> = ({ onClose }) => {
  return (
    <div className={styles.thanksWrapper}>
      <PHeading size={'medium'}>
        {t('sharedView.feedback.thanksHeading')}
      </PHeading>
      <PText>{t('sharedView.feedback.thanksText')}</PText>
      <PButton icon="close" variant="secondary" onClick={onClose}>
        {t('sharedView.feedback.closeButton')}
      </PButton>
    </div>
  )
}
