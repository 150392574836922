import React from 'react'
import styles from './FeedbackFlyout.module.css'
import { t } from 'i18next'
import { PButton } from '@porsche-design-system/components-react'

interface Props {
  onClick: () => void
}

export const FeedbackButton: React.FC<Props> = ({ onClick }) => {
  return (
    <PButton
      type="button"
      aria={{ 'aria-haspopup': 'dialog' }}
      onClick={onClick}
      className={styles.feedbackButton}
    >
      {t('sharedView.feedback.feedback')}
    </PButton>
  )
}
